import { FaqItem } from '@finn/auto-ui/types/faq';
import { useCurrentLocale, useDebouncedValue } from '@finn/ui-utils';
import trim from 'lodash/trim';
import useSWR from 'swr';

import { faqFetcher } from './faqFetcher';

const MIN_LENGTH = 15;
const DEBOUNC_INTERVAL = 1000;

type Props = {
  message?: string;
};

export const useRecommender = ({ message }: Props): FaqItem[] => {
  const { locale } = useCurrentLocale();
  const debouncedMessage = useDebouncedValue<string>(
    trim(message) || '',
    DEBOUNC_INTERVAL
  );
  const shouldFetch = debouncedMessage?.length > MIN_LENGTH;

  const { data } = useSWR(
    shouldFetch ? { message: debouncedMessage, locale } : null,
    faqFetcher,
    { keepPreviousData: true }
  );

  return data;
};
