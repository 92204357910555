// temp component
// todo: replace with design system component when available
import { cn, shouldShowFieldError } from '@finn/ui-utils';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import Checkbox from '~/components/FormElements/Checkbox/Checkbox';

type Props = {
  name: string;
  form: UseFormReturn<any>;
  classNameLabel?: string;
  children: React.ReactNode;
  id?: string;
};

export const HookFormCheckbox: React.FC<Props> = ({
  name,
  form,
  id,
  classNameLabel,
  children,
}) => {
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <div className="flex items-start gap-2 [&>span]:p-0 [&>span]:pt-1">
          <Checkbox
            error={shouldShowFieldError(fieldState, form.formState)}
            id={id || name}
            onCheckedChange={field.onChange}
            checked={field.value}
            {...field}
          />
          <label
            className={cn(
              'body-14-light [&_p]:body-14-light [&_a]:body-14-semibold before:inline after:inline [&_a]:underline [&_a]:before:inline [&_a]:after:inline',
              classNameLabel,
              {
                'text-red': shouldShowFieldError(fieldState, form.formState),
              }
            )}
            htmlFor={name}
          >
            {children}
          </label>
        </div>
      )}
    />
  );
};
