// temp component
// todo: replace with design system component when available
import { cn, shouldShowFieldError } from '@finn/ui-utils';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

type Props = {
  name: string;
  placeholder: string;
  form: UseFormReturn<any>;
  className?: string;
};

export const HookFormTextarea: React.FC<Props> = ({
  name,
  placeholder,
  form,
  className,
}) => {
  const i18n = useIntl();

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <div style={{ position: 'relative' }}>
          <textarea
            {...field}
            placeholder={placeholder}
            className={cn(
              'bg-background ring-offset-background body-16-regular border-pearl flex min-h-[128px] w-full rounded-sm border p-4 px-3 py-2 ring-black placeholder:text-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
              className
            )}
          />
          {shouldShowFieldError(fieldState, form.formState) && (
            <p className="text-red body-12-regular mt-3">
              {i18n.formatMessage({
                id: fieldState?.error?.message,
              })}
            </p>
          )}
        </div>
      )}
    />
  );
};
