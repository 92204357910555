import { HookFormCheckbox } from '@finn/auto-ui/components/FormElements/Checkbox/HookFormCheckbox';
import { HookFormTextarea } from '@finn/auto-ui/components/FormElements/HookFormTextarea';
import { SelectField } from '@finn/auto-ui/components/FormElements/SelectField';
import { LegalText } from '@finn/auto-ui/modules/legal/constants';
import { getLegalAllText } from '@finn/auto-ui/modules/legal/utils';
import { Button } from '@finn/design-system/atoms/button';
import { HookFormPhoneInput } from '@finn/platform-modules';
import { HookFormInput } from '@finn/ui-components';
import {
  B2BFormTypeCosmicData,
  B2BLeadGenFormTypeCosmicData,
  ContactUsFormTypeCosmicData,
  UIBaseCosmicObject,
} from '@finn/ui-cosmic';
import { cn, parseToHtml } from '@finn/ui-utils';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Ref, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { getInputPlaceholder } from '~/utils/cosmic';

import { FaqRecommender } from './FaqRecommender/FaqRecommender';

type B2BType = UIBaseCosmicObject<B2BFormTypeCosmicData>;
type ContactUsType = UIBaseCosmicObject<ContactUsFormTypeCosmicData>;
type PromoType = UIBaseCosmicObject<B2BLeadGenFormTypeCosmicData>;

type FormProps = {
  form: UseFormReturn<any>;
  loading: boolean;
  cosmicData: B2BType | ContactUsType | PromoType;
  isB2B?: boolean;
  messageFieldValue?: string;
};
const Form = ({
  form,
  loading,
  messageFieldValue,
  cosmicData,
  isB2B,
}: FormProps) => {
  const [showGdprDetails, setShowGdprDetails] = useState(false);
  const animationParent = useAutoAnimate(null);
  const i18n = useIntl();
  const gdprCheckbox = getLegalAllText(
    i18n.locale,
    LegalText.GDPR_GENERIC_CONTACT_FORM_CHECKBOX
  );
  const gdprDetails = getLegalAllText(
    i18n.locale,
    LegalText.GDPR_GENERIC_CONTACT_FORM_DETAILS
  );

  const createOptions = (options: string[]) =>
    options.map((topic: string) => ({ value: topic, label: topic }));

  const handleGDPRToggle = (e) => {
    e.preventDefault();
    setShowGdprDetails(!showGdprDetails);
  };

  return (
    <div>
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        {'message' in cosmicData.metadata.input_fields && !isB2B && (
          <div
            className="sm:col-start-1 sm:col-end-3"
            ref={animationParent as Ref<HTMLDivElement>}
          >
            <HookFormTextarea
              name="message"
              form={form}
              placeholder={getInputPlaceholder(cosmicData, 'message')}
            />
            <FaqRecommender messageFieldValue={messageFieldValue} />
          </div>
        )}
        {'support_topic' in cosmicData.metadata.input_fields && (
          <div className="sm:col-start-1 sm:col-end-3">
            <SelectField
              options={createOptions(
                (cosmicData as ContactUsType).metadata.dropdown_options
                  .support_topic
              )}
              placeholder={getInputPlaceholder(cosmicData, 'support_topic')}
              name="topic"
            />
          </div>
        )}
        <SelectField
          name="salutation"
          placeholder={getInputPlaceholder(cosmicData, 'salutation')}
          options={
            isB2B
              ? (cosmicData as B2BType).metadata.salutation_options
              : (cosmicData as ContactUsType).metadata.dropdown_options
                  .salutation
          }
        />

        {'company' in cosmicData.metadata.input_fields && (
          <div>
            <HookFormInput
              form={form}
              name="company"
              label={getInputPlaceholder(cosmicData, 'company')}
            />
          </div>
        )}
        <div>
          <HookFormInput
            form={form}
            name={isB2B ? 'firstName' : 'firstname'}
            label={getInputPlaceholder(cosmicData, 'first_name')}
          />
        </div>
        <div>
          <HookFormInput
            form={form}
            name={isB2B ? 'lastName' : 'lastname'}
            label={getInputPlaceholder(cosmicData, 'last_name')}
          />
        </div>
        <div>
          <HookFormInput
            form={form}
            name="email"
            type="email"
            label={getInputPlaceholder(cosmicData, 'email_address')}
          />
        </div>
        <div>
          <HookFormPhoneInput
            form={form}
            type="tel"
            name={isB2B ? 'phoneNumber' : 'phone'}
            label={getInputPlaceholder(cosmicData, 'phone_number')}
            placeholder={cosmicData.metadata.input_fields.phone_number_format}
          />
        </div>
        {'license_plate' in cosmicData.metadata.input_fields && (
          <div>
            <HookFormInput
              form={form}
              name={'licensePlate'}
              label={getInputPlaceholder(cosmicData, 'license_plate')}
            />
          </div>
        )}
        {'fleet_size' in cosmicData.metadata.input_fields && (
          <div>
            <HookFormInput
              form={form}
              name="fleetSize"
              label={getInputPlaceholder(cosmicData, 'fleet_size')}
              type="number"
            />
          </div>
        )}
        {'message' in cosmicData.metadata.input_fields && isB2B && (
          <div className="sm:col-start-1 sm:col-end-3">
            <HookFormTextarea
              name="message"
              form={form}
              placeholder={getInputPlaceholder(cosmicData, 'message')}
            />
          </div>
        )}
        <div
          className={cn([
            '-mt-2 sm:col-start-1 sm:col-end-3',
            '[&_a]:link-16 [&_a:after]:!hidden [&_a:before]:!hidden',
          ])}
        >
          <HookFormCheckbox form={form} name="gdpr">
            {gdprCheckbox}
          </HookFormCheckbox>
          <div className="[&_p]:body-14-regular ml-6 [&_p]:pt-1">
            <Button variant="action" size="md" onClick={handleGDPRToggle}>
              {i18n.formatMessage({
                id: `general.${
                  showGdprDetails ? 'lessInformation' : 'moreInformation'
                }`,
              })}
            </Button>
            {showGdprDetails && (
              <>
                <br />
                <br />
                <div className="[&_p]:body-14-regular [&_p]:pt-1">
                  {parseToHtml(gdprDetails)}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mb-4 sm:col-start-1 sm:col-end-3 sm:mb-6">
          <Button type="submit" loading={loading}>
            {cosmicData.metadata.submit_button.button_label}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Form;
